const Dropdowns = {
  init: function () {
    this.dropDown();
    this.dropDownSimple();
  },

  dropDown : function(){
    const dropDowns = document.querySelectorAll('[data-drop-down-js]');
    if (!dropDowns || dropDowns.length === 0) return;
    dropDowns.forEach((dropDown) => {
      let trigger = dropDown.querySelector('button');
      let list = dropDown.querySelector('ul');

      if (trigger) {
        trigger.addEventListener('click', () => {
          if (!dropDown.classList.contains('is-active')) {
            setTimeout(() => {
              setHeight(list);
              dropDown.classList.add('is-active');
            }, 0);
          } else {
            setHeight(list);
            dropDown.classList.remove('is-active');
            setTimeout(() => {
              list.style.height = null;
            }, 0);
          }
        })
      }
    })
    function setHeight(target) {
      target.style.height = target.scrollHeight + "px";
    }
  },

  dropDownSimple: function(){
    const dropDowns = document.querySelectorAll('[data-drop-down-simple-js]');
    if (!dropDowns || dropDowns.length === 0) return;

    dropDowns.forEach((dropDown) => {
      let trigger = dropDown.querySelector('button');

      if (trigger) {
        trigger.addEventListener('click', () => {
          if (!dropDown.classList.contains('is-active')) {
            dropDown.classList.add('is-active');
            document.addEventListener('click', outsideClickHandler);
          } else {
            dropDown.classList.remove('is-active');
            document.removeEventListener('click', outsideClickHandler);
          }
        })
      }
      function outsideClickHandler(event) {
        if (!dropDown.contains(event.target)) {
          dropDown.classList.remove('is-active');
          document.removeEventListener('click', outsideClickHandler);
        }
      }
    });
  },
};

export default Dropdowns;
