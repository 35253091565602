/* eslint-disable */

import autoComplete from '@tarekraafat/autocomplete.js';

const searchSettings = {

  init: function () {
    this.autoCompleteSettings();
  },

  autoCompleteSettings: function () {
    const autocompleteInput = document.querySelector('#autoComplete');
    if(!autocompleteInput) return;

    const autocompleteString = autocompleteInput.getAttribute('data-autocomplete-data-js');
    const autocompleteSrc = autocompleteString.split(', ');

    const autoCompleteJS = new autoComplete({
      selector: "#autoComplete",
      data: {
        src: autocompleteSrc,
        cache: true,
      },
      resultItem: {
        highlight: true,
      },
      events: {
        input: {
          selection: (event) => {
            const selection = event.detail.selection.value;
            autoCompleteJS.input.value = selection;
          }
        }
      },
      submit: true,
    });
  },
}

export default searchSettings;
