/* eslint-disable */


const Header = {
  init: function () {
    this.navigationSubNavs();
    this.mobileNav();
    this.searchModal();
    this.languageSwitcher();
  },


  /*todo: refactoring*/
  navigationSubNavs: function () {
    const triggers = document.querySelectorAll('[data-sub-nav-trigger-js]');
    if (!triggers || triggers.length === 0) return;

    const self = this;

    triggers.forEach((trigger) => {
      trigger.addEventListener('click', () => {
        const parent = trigger.parentNode;
        const currentSubNav = parent.querySelector('[data-sub-nav]');

        this.checkFocus(currentSubNav);
        if (parent.classList.contains('is-active')) {
          this.closeDesktopSubNav(parent, currentSubNav);
          document.removeEventListener('click', outsideClickHandler);
        } else {
          this.openDesktopSubNav(parent, currentSubNav);
          document.addEventListener('click', outsideClickHandler);
        }

        function outsideClickHandler(event) {
          if (!parent.contains(event.target)) {
            self.closeDesktopSubNav(parent, currentSubNav);
            document.removeEventListener('click', outsideClickHandler);
          }
        }
      })
    })
  },

  closeDesktopSubNav: function(parent, currentSubNav) {
    let currentTrigger = parent.querySelector('[data-sub-nav-trigger-js]');

    parent.classList.remove('is-active');

    currentTrigger.focus();

    this.removeElsFromTabindex(currentSubNav);
  },

  openDesktopSubNav: function(parent, currentSubNav) {
    parent.classList.add('is-active');

    let firstFocusableElement = currentSubNav.querySelector('a');
    firstFocusableElement.focus();
    this.addElsToTabindex(currentSubNav);
  },

  addElsToTabindex: function (currentSubNav) {
    if (!currentSubNav) return;

    let allFocusableElements = currentSubNav.querySelectorAll('a');

    allFocusableElements.forEach((el) => {
      el.setAttribute('tabindex', 0);
    });
  },

  removeElsFromTabindex: function (currentSubNav) {
    if (!currentSubNav) return;

    let allFocusableElements = currentSubNav.querySelectorAll('a');

    allFocusableElements.forEach((el) => {
      el.setAttribute('tabindex', -1);
    });
  },

  checkFocus: function (currentSubNav) {
    if (!currentSubNav) return;

    let self = this;
    let allFocusableElements = currentSubNav.querySelectorAll('a');
    let parent = currentSubNav.parentNode

    document.addEventListener('keydown', function (event) {
      if (event.key === 'Tab' || event.keyCode === 9) {
        if (parent.classList.contains('is-active')) {
          checkActiveLinks();
        }
      }
    });

    function checkActiveLinks() {
      requestAnimationFrame(function () {
        let isAnyFocused = Array.from(allFocusableElements).some(function (el) {
          return el === document.activeElement;
        });

        if (!isAnyFocused) {
          self.closeAllSubNavs();
        }
      });
    }
  },

  closeAllSubNavs: function () {
    let subNavs = document.querySelectorAll(`.header__nav-item.is-active`);
    if (subNavs.length) {
      subNavs.forEach((parent) => {
        let currentSubNav = parent.querySelector('[data-sub-nav]');
        this.closeDesktopSubNav(parent, currentSubNav);
      });
    }
  },

  /*----------------*/

  mobileNav: function () {
    const body = document.querySelector("body");
    const openNavTrigger = document.querySelector("[data-open-nav-js]");
    if (!openNavTrigger) return;

    openNavTrigger.addEventListener("click", () => {
      if (body.classList.contains("navigationOpen")) {
        this.closeMobileNav();
      } else {
        this.openMobileNav();
      }
    });
  },

  openMobileNav: function () {
    const body = document.querySelector("body");
    const html = document.querySelector("html");

    html.classList.add("overflowHidden");
    body.classList.add("overflowHidden");
    body.classList.add("navigationOpen");
  },

  closeMobileNav: function () {
    const body = document.querySelector("body");
    const html = document.querySelector("html");

    html.classList.remove("overflowHidden");
    body.classList.remove("overflowHidden");
    body.classList.remove("navigationOpen");
    if (body.classList.contains("langModalOpen")) {
      html.classList.remove("overflowHiddenSearch");
      body.classList.remove("overflowHiddenSearch");
      body.classList.remove("langModalOpen");
    }
  },

  searchModal: function () {
    let modalTriggers = document.querySelectorAll('[data-search-modal-trigger-js]');
    let searchModal = document.querySelector('[data-search-modal-js]');
    let closeBtn = document.querySelector('[data-close-search-modal-js]');
    if (!modalTriggers || modalTriggers.length === 0 || !searchModal) return;

    modalTriggers.forEach((trigger) => {
      trigger.addEventListener('click', () => {
        openSearchModal();
      })
    })

    if (closeBtn) {
      closeBtn.addEventListener('click', () => {
        closeSearchModal();
      })
    }

    function openSearchModal() {
      const body = document.querySelector("body");
      const html = document.querySelector("html");

      html.classList.add("overflowHiddenSearch");
      body.classList.add("overflowHiddenSearch");
      body.classList.add("searchModalOpen");
    }

    function closeSearchModal() {
      const body = document.querySelector("body");
      const html = document.querySelector("html");

      html.classList.remove("overflowHiddenSearch");
      body.classList.remove("overflowHiddenSearch");
      body.classList.remove("searchModalOpen");
    }
  },

  languageSwitcher: function () {
    let modalTriggers = document.querySelectorAll('[data-lang-modal-trigger-js]');
    let searchModal = document.querySelector('[data-lang-modal-js]');
    let closeBtn = document.querySelector('[data-close-lang-modal-js]');
    let langSearch = document.getElementById('langSearch');
    let langList = document.querySelectorAll('.lang-list li a');

    if (!modalTriggers || modalTriggers.length === 0 || !searchModal) return;

    modalTriggers.forEach((trigger) => {
      trigger.addEventListener('click', () => {
        openSearchModal();
      })
    })

    langSearch.addEventListener('keyup', function () {
      searchLang();
    });

    if (closeBtn) {
      closeBtn.addEventListener('click', () => {
        closeSearchModal();
      })
    }

    function searchLang() {
      let searchValue = langSearch.value.toLowerCase();
      langList.forEach((lang) => {
        lang.parentNode.style.order = '';
        if (lang.innerHTML.toLowerCase().includes(searchValue)) {
          lang.style.display = 'block';
          lang.parentNode.style.order = '-1';
        } else {
          lang.style.display = 'none';
        }
      });
    }

    function openSearchModal() {
      const body = document.querySelector("body");
      const html = document.querySelector("html");

      html.classList.add("overflowHiddenSearch");
      body.classList.add("overflowHiddenSearch");
      body.classList.add("langModalOpen");
    }

    function closeSearchModal() {
      const body = document.querySelector("body");
      const html = document.querySelector("html");

      html.classList.remove("overflowHiddenSearch");
      body.classList.remove("overflowHiddenSearch");
      body.classList.remove("langModalOpen");
    }

    // function search language
  },
  /*----------------*/

};

export default Header;
