const subscribeForms = {

  init: function () {
    const forms = document.querySelectorAll('[data-subscribe-form-js]');
    if (!forms || forms.length === 0) return;

    forms.forEach((form) => {
      let checkbox = form.querySelector('.gfield_checkbox');
      let checkboxText = form.querySelector('[data-checkbox-text-js]');
      if (checkbox) {
        let label = checkbox.querySelector('.gform-field-label');
        label.tabIndex = 0
        if (checkboxText) {
          label.innerHTML = '';
          label.append(checkboxText);
        }
      }
    })
  },
}

export default subscribeForms;
