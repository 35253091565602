import Header from './parts/layout/header';
import Search from './parts/layout/search';
import AnchorsScroll from './parts/components/anchor-scroll';
import subscribeForms from './parts/components/subscribe-forms';
import CommonAccessibility from './parts/components/accessibility';
import Dropdowns from './parts/components/dropdown-nav';
import AppHeight from './parts/components/app-height';

window.addEventListener('load', () => {
  AnchorsScroll.init();
  subscribeForms.init();
  Header.init();
  CommonAccessibility.init();
  Search.init();
  Dropdowns.init();
  AppHeight.init();
});

window.addEventListener('resize', () => {
});
